/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type GetAuditsEventTypeItem = typeof GetAuditsEventTypeItem[keyof typeof GetAuditsEventTypeItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetAuditsEventTypeItem = {
  'project-created': 'project-created',
  'project-updated': 'project-updated',
  'project-archived': 'project-archived',
  'input-created': 'input-created',
  'input-updated': 'input-updated',
  'input-archived': 'input-archived',
  'bfmc-created': 'bfmc-created',
  'bfmc-updated': 'bfmc-updated',
  'bfmc-archived': 'bfmc-archived',
  'run-created': 'run-created',
  'run-updated': 'run-updated',
  'run-archived': 'run-archived',
  'run-started': 'run-started',
  'run-failed': 'run-failed',
  'run-completed': 'run-completed',
  'run-accepted': 'run-accepted',
} as const;
