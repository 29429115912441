/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type AppAuditRunStartedEventType = typeof AppAuditRunStartedEventType[keyof typeof AppAuditRunStartedEventType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppAuditRunStartedEventType = {
  'run-started': 'run-started',
} as const;
