/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type ServiceProjectTypeAttribute = typeof ServiceProjectTypeAttribute[keyof typeof ServiceProjectTypeAttribute];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ServiceProjectTypeAttribute = {
  bfrmp: 'bfrmp',
  'statewide-maxfuels': 'statewide-maxfuels',
  'statewide-snapshot': 'statewide-snapshot',
} as const;
