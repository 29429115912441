/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type AppRunStatus = typeof AppRunStatus[keyof typeof AppRunStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppRunStatus = {
  Complete: 'Complete',
  In_progress: 'In progress',
  In_setup: 'In setup',
  Failed: 'Failed',
} as const;
