/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type AppNotificationSettingsDatasetUpdate = typeof AppNotificationSettingsDatasetUpdate[keyof typeof AppNotificationSettingsDatasetUpdate];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppNotificationSettingsDatasetUpdate = {
  none: 'none',
  'on-demand': 'on-demand',
  daily: 'daily',
  weekly: 'weekly',
} as const;
