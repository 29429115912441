/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type AppRunType = typeof AppRunType[keyof typeof AppRunType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppRunType = {
  Phoenix: 'Phoenix',
  PhoenixPostprocessing: 'PhoenixPostprocessing',
} as const;
