/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type AppFileStatusColdStorageStatus = typeof AppFileStatusColdStorageStatus[keyof typeof AppFileStatusColdStorageStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppFileStatusColdStorageStatus = {
  'cold-storage': 'cold-storage',
} as const;
