/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type ServiceInputType = typeof ServiceInputType[keyof typeof ServiceInputType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ServiceInputType = {
  asset: 'asset',
  assets: 'assets',
  'asset-costs': 'asset-costs',
  boundary: 'boundary',
  classifications: 'classifications',
  dem: 'dem',
  disruptions: 'disruptions',
  'fdr-probabilities': 'fdr-probabilities',
  'fire-history': 'fire-history',
  fuel: 'fuel',
  'fuel-type': 'fuel-type',
  ignitions: 'ignitions',
  'phoenix-settings': 'phoenix-settings',
  'postprocessing-settings': 'postprocessing-settings',
  projection: 'projection',
  'road-proximity': 'road-proximity',
  suppression: 'suppression',
  'user-layer': 'user-layer',
  'vulnerability-curves': 'vulnerability-curves',
  weather: 'weather',
  'wind-modifiers': 'wind-modifiers',
} as const;
