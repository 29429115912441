/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type GetProjectsSortBy = typeof GetProjectsSortBy[keyof typeof GetProjectsSortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetProjectsSortBy = {
  status: 'status',
  '-status': '-status',
  name: 'name',
  '-name': '-name',
  createdAt: 'createdAt',
  '-createdAt': '-createdAt',
} as const;
